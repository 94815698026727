import axios from './axios';

// service to manage posted forms

interface IFormService {
  post: (url: string, formid: string | number) => (data: any) => Promise<any>;
}

export const FormService: IFormService = {
  post(url, formid) {
    return async (data) => {
      // remove filestamp from data if it exists
      const filestamp = data['filestamp'];

      if (filestamp) {
        delete data.filestamp;
      }

      const dataToSubmit = {
        // attach formid
        id: formid,

        // add filestamp if it exists
        ...(filestamp && { filestamp }),

        // take all fields and move em into "json" object
        data: Object.keys(data).map((fieldName) => ({
          name: fieldName,
          value: data[fieldName],
        })),
      };

      const response = await axios.post(url, dataToSubmit);
      return response.data;
    };
  },
};
