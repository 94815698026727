export const fileInputLabel = (
  type: 'images' | 'documents' | 'videos' | 'all'
) => {
  switch (type) {
    case 'images':
      return 'Bilder hierher ziehen';
    case 'documents':
      return 'Dokumente hierher ziehen';
    case 'videos':
      return 'Videos hierher ziehen';
    default:
      return 'Dateien hierher ziehen';
  }
};

export const getExtension = (filename: string) => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

export const isImage = (filename: string) => {
  const extension = getExtension(filename);
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      return true;
  }

  return false;
};

export const isDocument = (filename: string) => {
  const extension = getExtension(filename);
  switch (extension.toLowerCase()) {
    case 'pdf':
    case 'xlsx':
    case 'xls':
    case 'docx':
    case 'doc':
    case 'odt':
      return true;
  }

  return false;
};

export const isVideo = (filename: string) => {
  const extension = getExtension(filename);
  switch (extension.toLowerCase()) {
    case 'mp4':
      return true;
  }

  return false;
};

export const getFileType = (filename: string) => {
  if (isVideo(filename)) return 'video';
  if (isImage(filename)) return 'image';
  return 'attachment';
};
