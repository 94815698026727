import { useCallback, useEffect, useRef, useState } from 'react';
import { getCookie, setCookie } from '../../../utils';
import ContentWrapper from '../../layout/ContentWrapper';
import Button from '../Button';

import { PrivacyContent } from './content';

export const PrivacyBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const observeMe = useRef<any>(null);

  const createObserver = useCallback(
    () =>
      new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setDisableButton(false);
          } else {
            setDisableButton(true);
          }
        },
        {
          threshold: 1,
        }
      ),
    []
  );

  const closeBanner = () => {
    setShowBanner(false);
    setCookie('datenschutz_akzeptiert', true);
    document.body.style.overflowY = 'visible';
  };

  const observer = createObserver();

  useEffect(() => {
    const cookie = getCookie('datenschutz_akzeptiert');
    const div = observeMe.current;

    if (!cookie) {
      setShowBanner(true);
    }

    if (showBanner) {
      if (div) {
        observer.observe(div);
      }
    }

    return () => {
      if (div) {
        observer.unobserve(div);
      }

      observer.disconnect();
    };
  }, [observer, showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed inset-0 w-full h-full z-50 flex flex-col items-center justify-center bg-white">
      <div className="bg-white mx-2 px-4 max-w-full md:max-w-5xl max-h-9/12 rounded-md shadow-md p-4 overflow-y-scroll">
        <ContentWrapper>
          <PrivacyContent />
        </ContentWrapper>
        <div ref={observeMe as any}></div>
      </div>
      <div className="mt-5 mx-4 md:mt-10 pb-4 lg:pb-10 text-center">
        <Button size="small" disabled={disableButton} handleClick={closeBanner}>
          Ich habe die Datenschutzerklärung gelesen.
        </Button>
        {disableButton && (
          <p className="text-sm mt-2 leading-tight">
            Bitte lesen Sie zunächst die vollständige Datenschutzerklärung.
          </p>
        )}
      </div>
    </div>
  );
};
