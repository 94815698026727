import axios from './axios';

// service to manage posted forms
type TMailService = {
  send: (url: string) => (data: any) => Promise<any>;
};

export const MailService: TMailService = {
  send(url) {
    return async (data) => {
      const response = await axios.post(url, data);
      return response.data;
    };
  },
};
