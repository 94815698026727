import loadable from '@loadable/component';
import Loader from '../Loader';

function LazyPage<PageProps>(page: string) {
  return loadable<PageProps>(() => import(`../../../views/${page}`), {
    fallback: <Loader />,
  });
}

const LazyApp = (app: string) =>
  loadable(() => import(`../../../${app}`), {
    fallback: <Loader />,
  });

function LazyComponent<Props>(componentPath: string) {
  return loadable<Props>(() => import(`../../../components/${componentPath}`), {
    fallback: <Loader />,
  });
}

export { LazyPage, LazyApp, LazyComponent };
