import axios from './axios';

interface IContentService {
  get: (url: string) => Promise<any>;
  getJsonServer: (url: string) => Promise<any>;
  delete: (url: string) => Promise<any>;
  post: (
    url: string,
    postType?: 'json' | 'formdata'
  ) => (data: any) => Promise<any>;
  put: (
    url: string,
    postType?: 'json' | 'formdata'
  ) => (data: any) => Promise<any>;
}

export const ContentService: IContentService = {
  async get(url) {
    const content = await axios.get(url);
    return content.data;
  },

  async getJsonServer(url) {
    const content = await axios.get(url);
    return content.data;
  },

  async delete(url) {
    return await axios.delete(url);
  },

  post(url, postType = 'json') {
    return async (data) => {
      let response;

      if (postType === 'formdata') {
        const fileKeys = ['image', 'images', 'document', 'attachments'];
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (fileKeys.includes(key) && data[key]) {
            formData.append(key, data[key][0]);
          } else {
            formData.append(key, data[key]);
          }
        });

        response = await axios.post(url, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
      } else {
        response = await axios.post(url, data);
      }

      return response.data;
    };
  },

  put(url, postType = 'json') {
    return async (data) => {
      let response;

      if (postType === 'formdata') {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (key !== 'image' && key !== 'document' && key !== 'attachments') {
            formData.append(key, data[key]);
          } else {
            formData.append(key, data[key][0]);
          }
        });

        // post method because php put handling is ugly
        response = await axios.post(url, formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });
      } else {
        response = await axios.put(url, data);
      }

      return response.data;
    };
  },
};
