import axios from 'axios';
import { CONSTANTS } from '../types';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = CONSTANTS.BACKEND_URL;

axios.interceptors.response.use((config) => {
  if (config.status === 401) {
    window.location.href = CONSTANTS.FRONTEND_HOME_URL;
  }
  return config;
});

export default axios;
