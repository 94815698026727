import { AxiosResponse } from 'axios';
import { ILoginData, IUser, IFullUser } from '../types';

import axios from './axios';

interface IAuthService {
  login: ({
    username,
    password,
    permanent,
  }: ILoginData) => Promise<AxiosResponse<IUser>>;
  logout: () => Promise<AxiosResponse>;
  getUserData: (id?: number) => Promise<AxiosResponse<IFullUser>>;
  getMyData: () => Promise<AxiosResponse<IFullUser>>;
}

export const AuthService: IAuthService = {
  async login({ username, password, permanent }) {
    const response = await axios.post('/login', {
      username,
      password,
      ...(permanent && { permanent: '1' }),
    });

    return response;
  },

  async logout() {
    const response = await axios.post('/logout');
    return response;
  },

  async getUserData(id) {
    const response = await axios.get(`/users/${id}`);
    return response;
  },

  async getMyData() {
    const response = await axios.get('/users/me');
    return response;
  },
};
