import * as React from 'react';

interface IContentWrapperProps {
  children: React.ReactNode;
  withDividerBottom?: boolean;
  withDividerTop?: boolean;
}

const ContentWrapper = ({
  children,
}: //   withDividerBottom,
//   withDividerTop,
IContentWrapperProps) => {
  //   const dividerclasses =
  //     'w-3/4 md:w-7/12 xl:w-5/12 border-primary-500 border-t-2 my-3 lg:border-t-4 lg:my-4';
  return (
    <>
      {/* {withDividerTop && <hr className={dividerclasses} />} */}
      <div className="container mx-auto px-4 sm:px-6 md:px-8 py-4 md:py-8 xl:py-10">
        {children}
      </div>
      {/* {withDividerBottom && <hr className={dividerclasses} />} */}
    </>
  );
};

export default ContentWrapper;
