import * as React from 'react';
import classNames from 'classnames';

import { StringAsKey } from '../../../types';
import Image from '../Image';

import ArrowRightLB from '../../../assets/images/arrow-right-lb.svg';
import { HiExclamation } from 'react-icons/hi';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  handleClick?: (data: any) => void;
  color?: 'primary' | 'danger';
  withArrow?: boolean;
}

const Button = ({
  type = 'button',
  size = 'medium',
  color = 'primary',
  children,
  disabled = false,
  handleClick,
  withArrow = true,
}: IButton) => {
  const colors: StringAsKey = {
    primary: 'text-primary-500 hover:text-secondary-500 hover:underline',
    danger: 'text-red-800 hover:text-red-500 hover:underline',
  };

  const fontSizes: StringAsKey = {
    tiny: 'text-xs',
    small: 'text-sm md:text-base',
    medium: 'text-base md:text-lg xl:text-xl',
    large: 'text-lg md:text-xl xl:text-2xl',
  };

  const className = classNames(
    'relative font-bold transition-colors duration-150 inline-flex items-center group leading-tight',
    colors[color],
    fontSizes[size],
    {
      'opacity-25 pointer-events-none': disabled,
    }
  );

  return (
    <button
      className={className}
      type={type}
      {...(handleClick && { onClick: handleClick })}
    >
      <span className="text-left">{children}</span>

      {color === 'primary' && withArrow && (
        <Image
          className={`w-6 md:w-8 ml-2 duration-150 transform group-hover:translate-x-3 ${
            size === 'large' && 'xxl:w-10'
          }`}
          alt="Pfeil nach rechts"
          url={ArrowRightLB}
        />
      )}
      {color === 'danger' && (
        <HiExclamation className="w-5 h-5 ml-2 duration-150 transition-colors group-hover:text-red-500 text-red-800" />
      )}
    </button>
  );
};

export default Button;
