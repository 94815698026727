interface IImageProps {
  url: string;
  alt: string;
  className?: string;
  lazy?: boolean;
}

const Image = ({ url, alt, className, lazy = true }: IImageProps) => {
  return (
    <img
      className={className?.length ? className : 'w-full max-w-full'}
      src={url}
      alt={alt}
      loading={lazy ? 'lazy' : undefined}
    />
  );
};

export default Image;
