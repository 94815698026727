import create, { SetState } from 'zustand';
import { AuthService } from '../api';
import { IStore } from '../types';
import { togglePending, updateUser } from './helper';

export const useStore = create<IStore>((set: SetState<any>) => ({
  // Common
  toaster: {
    msg: null,
    type: 'success',
  },

  setToaster(msg, type) {
    set(() => ({
      toaster: {
        msg,
        type: type ? type : 'success',
      },
    }));
  },

  // Auth
  user: null,
  isPending: false,
  isAuthenticated: false,
  isAdmin: false,

  login: async ({ username, password, permanent }) => {
    const response = await AuthService.login({ username, password, permanent });
    updateUser(response, set);
  },

  logout: async () => {
    const response = await AuthService.logout();
    updateUser(response, set);
  },

  getUser: async () => {
    togglePending(set);
    let response;

    try {
      response = await AuthService.getMyData();
    } catch (err: any) {
      response = err.response;
    }

    updateUser(response, set);
    togglePending(set);
  },

  setAuthenticated(authenticated) {
    set(() => ({
      isAuthenticated: authenticated,
    }));
  },
}));
