import axios from './axios';

type TFileService = {
  upload: (files: File[], url: string, contentid: number) => Promise<any>;
};

export const FileService: TFileService = {
  async upload(files: File[], url, contentid) {
    const contenturl = `/${url}/${contentid}`;

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(contenturl, formData);
    }
  },
};
