import { useEffect } from 'react';
import shallow from 'zustand/shallow';

import { useStore } from './store';

import { LazyApp } from './components/common/LazyComponents';
import Loader from './components/common/Loader';
import { PrivacyBanner } from './components/common/PrivacyBanner';

const AuthenticatedApp = LazyApp('AuthenticatedApp');
const UnauthenticatedApp = LazyApp('UnauthenticatedApp');

const App = () => {
  const [isAuthenticated, isPending, getUser] = useStore(
    (state) => [state.isAuthenticated, state.isPending, state.getUser],
    shallow
  );

  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  if (isPending) return <Loader />;
  return (
    <>
      <PrivacyBanner />
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
};

export default App;
