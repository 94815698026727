/* eslint-disable react/no-unescaped-entities */

type TPrivacyContentProps = {
  noheading?: boolean;
};

export const PrivacyContent = ({ noheading = false }: TPrivacyContentProps) => (
  <div className="mce-content-body break-words">
    {!noheading && <h1 className="text-center">Datenschutzerklärung</h1>}

    {/* HIER DATENSCHUTZERKLÄRUNG REIN OHNE H1 */}
    <p>
      Wir, die SIKA Dr. Siebert und Kühn GmbH & Co. KG (SIKA), Struthweg 7-9,
      34260, Kaufungen, +79 5605 8030, info@sika.net möchten Ihnen nachstehend
      erklären, welche Daten wir auf dieser Webseite von Ihnen wie verarbeiten.
      Bei Fragen zum Datenschutz steht Ihnen unser Datenschutzbeauftragter unter
      sika@dsb-moers.de gerne zur Verfügung. Weitere Kontaktdaten finden Sie
      unter www.dsb-moers.de.
    </p>
    <p>
      <b>
        Zweck, Rechtsgrundlage, Kategorien von Empfängern, Speicherdauer der
        Datenverarbeitung
      </b>
    </p>
    <p>
      Zweck der Datenverarbeitung auf dieser Webseite ist die Information von
      Mitarbeitenden von SIKA über aktuelle Themen, Bereitstellung von
      Dokumenten und die Möglichkeit, zielgerichtet mit einzelnen Abteilungen
      Verbindung aufzunehmen (u.a. Feedbackfunktion, Ideenmanagement).
    </p>
    <p>
      Personenbezogene Daten bearbeiten wir gemäß folgenden Rechtsgrundlagen:
    </p>
    <ul>
      <li>
        Art. 88 DSGVO in Verbindung mit der Betriebsvereinbarung „SIKA
        Mitarbeiter Web App“.
      </li>
      <li>
        Art. 6 Abs. 1 lit. c DSGVO für die erforderliche Bearbeitung von
        personenbezogenen Daten zur Erfüllung einer rechtlichen Verpflichtung,
        der wir gemäß allenfalls anwendbarem Recht der EU oder gemäß allenfalls
        anwendbarem Recht eines Landes, in dem die DSGVO ganz oder teilweise
        anwendbar ist, unterliegen.
      </li>
      <li>
        Art. 6 Abs. 1 lit. f DSGVO für die erforderliche Bearbeitung von
        personenbezogenen Daten um die berechtigten Interessen von uns oder von
        Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte
        sowie Interessen der betroffenen Person überwiegen. Berechtigte
        Interessen sind insbesondere unser betriebswirtschaftliches Interesse,
        unsere Website bereitstellen zu können, die Informationssicherheit, die
        Durchsetzung von eigenen rechtlichen Ansprüchen und die Einhaltung
        weiterer Rechtsvorschriften.
      </li>
    </ul>
    <p>
      Für die Bereitstellung der Webseite setzen wir im Rahmen der
      Leistungserbringung gesondert zur Verschwiegenheit und auf den Datenschutz
      verpflichte Dienstleistungsunternehmen ein. Eine Weitergabe an Behörden
      erfolgt ausschließlich bei Vorliegen vorrangiger Rechtsvorschriften. Eine
      Übermittlung von Daten in Drittstaaten erfolgt nicht.
    </p>
    <p>
      Bei Ihrem Besuch der Website wird eine Verbindung mit Ihrem Browser
      hergestellt. Diese nachstehenden dabei erhobenen Informationen werden
      temporär in Systemdateien abgelegt und automatisch erfasst: IP-Adresse
      Ihres Geräts, Datum und Uhrzeit des Zugriffs, Name und URL von abgerufenen
      Dateien, Website, von der aus der Zugriff erfolgt bzw. von wo aus Sie auf
      unsere Seite geleitet wurden (Referrer-URL), verwendeter Browser und ggf.
      das Betriebssystem Ihres Geräts sowie der Name Ihres Providers.
    </p>
    <p>
      Die genannten Daten werden durch uns zu Zwecken des reibungslosen
      Verbindungsaufbaus und der Systemsicherheit verarbeitet. Die anfallenden
      Verbindungsdaten werden automatisch gelöscht und grundsätzlich nicht
      länger als sieben Tage gespeichert. Sofern die Webseite missbräuchlich
      genutzt wird, werden Log-Daten, deren weitere Aufbewahrung zu
      Beweiszwecken erforderlich ist, bis zur Klärung des Vorfalls aufgehoben.
    </p>
    <p>
      Bei der SIKA Mitarbeiter-App handelt es sich um eine Web-Applikation. Sie
      können die Web-Applikation vom Browser Ihres PCs als auch Ihres
      Smartphones öffnen. Die Web-Applikation benötigt keinen Zugriff auf
      Funktionen Ihres Smartphones.
    </p>
    <p>
      <b>Nutzung von Cookies</b>
    </p>
    <p>
      Diese Website verwendet sog. "Cookies", Textdateien, die auf Ihrem
      Computer gespeichert werden und die eine Speicherung der Benutzung der
      Website durch Sie ermöglichen.
    </p>
    <p>
      Der Einsatz von Cookies ist für die Funktionalität der Webseite nötig, wir
      setzen Cookies auf Basis unserer berechtigten Interessen ein. Die
      Rechtsgrundlage für die Datenverarbeitung ist dann Art. 6 Abs. 1 lit. f
      DSGVO (berechtigte Interessen). Die Cookies werden nach Schließen Ihres
      Browsers gelöscht. Sie können die Installation der Cookies durch eine
      entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen
      Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
      sämtliche Funktionen dieser Website vollumfänglich nutzen können. Die
      Datenerhebung erfolgt anonymisiert; die erhobenen Daten sind nicht auf
      Ihre Person beziehbar.
    </p>
    <p>
      <b>Nutzerkonto und Webseitenfunktionen</b>
    </p>
    <p>
      Eine Anmeldung an der Webseite ist nur mit einem Nutzerkonto möglich. Sie
      erhalten Ihre Anmeldedaten über den App-Support per Post. Sie haben die
      Möglichkeit, freiwillig weitere personenbezogene Daten in der SIKA
      Mitarbeiter-App zu hinterlegen.
    </p>
    <p>
      Es besteht keine Verpflichtung, die SIKA Mitarbeiter-App zu nutzen. Ihnen
      entstehen keine Nachteile, wenn Sie die SIKA-App nicht nutzen. Alle
      Informationen stehen Ihnen auch über Aushänge zur Verfügung. Die SIKA
      Mitarbeiter-App ermöglicht Ihnen Kommunikationsfunktionen mit
      verschiedenen Abteilungen z.B. Ideenmanagement, Feedbackfunktionen oder
      auch die Möglichkeit, Rückmeldungen an die Personalabteilung zu geben.
    </p>
    <p>
      Sie können die Nutzung der App jederzeit mit Wirkung für die Zukunft
      beenden. Eine kurze Nachricht per E-Mail an den Support genügt. Ihr
      Nutzerkonto wird dann in der App gelöscht.
    </p>
    <p>
      <b>
        Ihr Recht auf Auskunft, Berichtigung, Löschung, Widerspruch und
        Datenübertragbarkeit
      </b>
    </p>
    <p>
      Sie können jederzeit Ihr Recht auf Auskunft, Berichtigung und Löschung von
      Daten wahrnehmen. Kontaktieren Sie uns einfach auf den oben beschriebenen
      Wegen. Sofern Sie eine Datenlöschung wünschen, wir aber noch gesetzlich
      zur Aufbewahrung verpflichtet sind, wird der Zugriff auf Ihre Daten
      eingeschränkt (gesperrt). Gleiches gilt bei einem Widerspruch. Ihr Recht
      auf Datenübertragbarkeit können Sie wahrnehmen, soweit die technischen
      Möglichkeiten beim Empfänger und bei uns zur Verfügung stehen.
    </p>
    <p>
      <b>Beschwerderecht</b>
    </p>
    <p>
      Sie haben jederzeit die Möglichkeit, eine Beschwerde bei einer
      Datenschutz-Aufsichtsbehörde einzureichen.
    </p>
    <p>
      <b>Datensicherheit/ Verschlüsselung </b>
    </p>
    <p>
      Diese Webseite nutzt „Hypertext Transfer Protocol Secure“ (https). Die
      Verbindung zwischen Ihrem Browser und unserem Server erfolgt
      verschlüsselt.
    </p>
    <p>
      <b>Aktualität und Änderung dieser Datenschutzerklärung</b>
    </p>
    <p>
      Wir behalten uns vor, den Inhalt dieser Datenschutzerklärung jederzeit
      anzupassen. Dies erfolgt in der Regel bei Weiterentwicklung oder Anpassung
      der eingesetzten Dienste. Die aktuelle Datenschutzerklärung können Sie auf
      unserer Webseite einsehen. Stand dieser Erklärung: 15.12.2021
    </p>
    {/* HIER ENDE DATENSCHUTZERKLÄRUNG */}
  </div>
);
