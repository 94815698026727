import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import { ContentService } from './api';

import './tailwind.dist.css';

ReactDOM.render(
  <div className="safe-top safe-left safe-right safe-bottom overflow-x-hidden">
    <SWRConfig
      value={{
        fetcher: ContentService.get,
      }}
    >
      <App />
    </SWRConfig>
  </div>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
